import PageSections from "@/components/PageSections";
import craft from "@/lib/craft";
import { returnStaticProps } from "@/lib/returnStaticProps";
import QueryHomepage from "@/queries/QueryHomepage.graphql";
import { GetStaticProps } from "next";
import Head from "next/head";
import { Seomatic } from "react-seomatic";

export const getStaticProps: GetStaticProps = async (context) => {
  const uri = "/";

  const response = await craft(QueryHomepage);

  const { pageEntry, primaryNavigation, footerNavigation } = response;

  const returnResponse = await returnStaticProps(
    {
      navigation: {
        primaryNavigation,
        footerNavigation,
      },
      pageEntry,
    },
    !pageEntry,
    uri
  );

  return returnResponse;
};

export default function Page({ pageEntry }): JSX.Element {
  return (
    <>
      <Seomatic Head={Head} {...pageEntry.seomatic} />
      <PageSections sections={pageEntry.pageSections} />
    </>
  );
}
